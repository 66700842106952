import {
    AddressPubKeySchema,
    AddressSchema,
    BlockHeight,
    BtcAddressSchema,
    BtcMessageSchema,
    FeeRateSchema,
    InscriptionIdSchema,
    OrdinalAddressSchema,
    PsbtSchema,
    SatoshiSchema,
    ScriptPubKeySchema,
    SigHashSchema,
    SignatureSchema,
    SignInputsSchema,
    TxidSchema,
    UtxoIndexSchema,
    WalletSchema
} from '@/schemas/bitcoin';
import { AuctionCycle, AuctionCycleSchema, AuctionDetails, Bid, KitDateBidSummary, RankedBidSchema } from '@/schemas/auction';
import { KitDate, KitDateSchema } from '@/schemas/kit';
import { z } from 'zod';

// Type definitions
type AccountBid = z.infer<typeof AccountBidSchema>;
type AccountConnect = z.infer<typeof AccountConnectSchema>;
type AccountInfo = z.infer<typeof AccountInfoSchema>;
type CreateBid = z.infer<typeof CreateBidSchema>;
type CreateBidResponse = z.infer<typeof CreateBidResponseSchema>;
type DateRegister = z.infer<typeof DateRegisterSchema>;
type EmailRegistration = z.infer<typeof EmailRegistrationSchema>;
type Utxo = z.infer<typeof UtxoSchema>;

export interface AgoraAPI {
    // Auction related
    getAuctionList(): Promise<AuctionDetails[]>;
    getCurrentAuction(): Promise<AuctionDetails>;
    getAuctionBidDates(auctionCycle: AuctionCycle): Promise<{ bidDates: KitDateBidSummary[] }>;
    getBids(auctionCycle: AuctionCycle, kitDate: KitDate): Promise<{ bids: Bid[] }>;

    // Block height
    getBlockHeight(): Promise<BlockHeight>;

    // Date related
    getDatesTaken(): Promise<{ datesTaken: KitDate[] }>;

    // Account related
    getAccountStatus(): Promise<{ accountStatus: AccountInfo }>;
    connectWallet(data: AccountConnect): Promise<void>;
    disconnectWallet(): Promise<void>;
    registerEmail(data: EmailRegistration): Promise<AccountInfo>;

    // Bidding
    createBid(data: CreateBid): Promise<CreateBidResponse>;
    submitBid(data: AccountBid): Promise<Bid>;

    // Kit related
    getKitIds(): Promise<string[]>;
    getKitMetadata(inscriptionId: string): Promise<any>; // Replace 'any' with a proper metadata type

    // UTXO related
    getUtxos(address: string): Promise<Utxo[]>;
}

export type {
    AccountBid, AccountConnect, AccountInfo, CreateBid,
    CreateBidResponse,
    DateRegister,
    EmailRegistration,
    Utxo
};

export {
    AccountBidSchema, AccountConnectRequestSchema,
    AccountConnectSchema, AccountInfoSchema, AccountStatusSchema,
    AgoraResponseSchema,
    ConnectMessageSchema, CreateBidResponseSchema, CreateBidSchema, DateRegisterSchema,
    EmailRegistrationSchema,
    KitDateSchema,
    UtxoSchema
};

const EmailSchema = z.string().email().nullable();
const AccountStatusSchema = z.enum(['blocked', 'registered']);

// Request schemas
const AccountConnectRequestSchema = z.object({
    btc_address: BtcAddressSchema,
    ordinal_address: OrdinalAddressSchema,
    btc_address_pub_key: AddressPubKeySchema,
    message: BtcMessageSchema,
    wallet: WalletSchema,
    signature: SignatureSchema,
});

const AccountConnectSchema = AccountConnectRequestSchema.transform(
    (data) => {
        return {
            ordinal_address: data.ordinal_address,
            btc_address: data.btc_address,
            signature: data.signature,
            message: data.message,
        };
    }
);

const AccountBidSchema = z.object({
    auction_cycle: AuctionCycleSchema,
    kit_date: KitDateSchema,
    ordinal_address: OrdinalAddressSchema,
    psbt: PsbtSchema,
});

const CreateBidSchema = z.object({
    auction_cycle: AuctionCycleSchema,
    kit_date: KitDateSchema,
    ordinal_address: OrdinalAddressSchema,
    btc_address: BtcAddressSchema,
    btc_address_pub_key: AddressPubKeySchema,
    amount: SatoshiSchema,
    fee_rate: FeeRateSchema,
    bidding_address: AddressSchema,
});

const CreateBidResponseSchema = z.object({
    auction_cycle: AuctionCycleSchema,
    btc_address: BtcAddressSchema,
    kit_date: KitDateSchema,
    ordinal_address: OrdinalAddressSchema,
    psbt: PsbtSchema,
    sighash: SigHashSchema,
    sign_inputs: SignInputsSchema,
});

const DateRegisterSchema = z.object({
    kit_date: KitDateSchema,
    email: EmailSchema,
});

const EmailRegistrationSchema = z.object({
    email: z.string().email().nullable()
});

// Response schemas

const ConnectMessageSchema = z.object({
    message: z.string(),
});

const UtxoSchema = z.object({
    address: AddressSchema,
    index: UtxoIndexSchema,
    script_pubkey: ScriptPubKeySchema,
    txid: TxidSchema,
    value: SatoshiSchema,
    bid_uuid: z.string().uuid().nullish().optional(),
});

const AccountInfoSchema = z.object({
    bids: z.array(RankedBidSchema),
    kits: z.array(InscriptionIdSchema),
    ordinal_address: OrdinalAddressSchema,
    status: AccountStatusSchema,
    email: EmailSchema,
    last_updated: z.string().datetime(),
});

// API response schema
const AgoraErrorSchema = z.discriminatedUnion('type', [
    z.object({ type: z.literal('AccountNotRegistered'), message: z.string() }),
    z.object({ type: z.literal('AuctionCreation'), message: z.string() }),
    z.object({ type: z.literal('DatabaseError'), message: z.string() }),
    z.object({ type: z.literal('DateTaken'), date: KitDateSchema, message: z.string() }),
    z.object({ type: z.literal('Finalize'), error: z.string(), message: z.string() }),
    z.object({ type: z.literal('Internal'), error: z.string(), message: z.string() }),
    z.object({ type: z.literal('InvalidAmount'), amount: z.number(), message: z.string() }),
    z.object({ type: z.literal('InvalidDate'), date: KitDateSchema, message: z.string() }),
    z.object({ type: z.literal('InvalidPsbt'), error: z.string(), message: z.string() }),
    z.object({ type: z.literal('NoRunningAuction'), message: z.string() }),
    z.object({ type: z.literal('SatNotAvailable'), date: KitDateSchema, message: z.string() }),
    z.object({ type: z.literal('WrongAuctionCycle'), cycle: z.number().int(), message: z.string() }),
]);

const AgoraResponseSchema = z.discriminatedUnion('status', [
    z.object({ status: z.literal('success'), data: z.unknown() }),
    z.object({ status: z.literal('error'), error: AgoraErrorSchema }),
]);
